export const statsDataHero = [
  { label: "Data Points", value: 5, unit: "M", prefix: "+" },
  { label: "Market Models", value: 10, unit: "", prefix: "+" },
  { label: "Smarter Data", value: 100, unit: "x", prefix: "" },
];


export const slidesRealtimeAiPowered = [
  {
    image: "/images/dashboardOverview.png",
    alt: "Dashboard Overview",
  },
  {
    image: "/images/realtimeTechnicals.png",
    alt: "Realtime Technicals",
  },
  {
    image: "/images/realtimeMarket.png",
    alt: "Realtime Market",
  },
];


export const productsData = [
  {
    title: "Dashboard",
    description:
      "Experience the oracle Real time aggregated market data feed and impact predictions. trading strategy, indicators and more.",
    image: "/images/productDashboard.png",
    link: "https://dashboard.yoracle.ai",
    isComingSoon: false,
  },
  {
    title: "Telegram App",
    description:
      "Access the Oracle, Discover market movements, trends and alpha.",
    image: "/images/productTgApp.png",
    link: "",
    isComingSoon: true,
  },
  {
    title: "API Key",
    description:
      "Supercharge your application by accessing the most accurate, lowest-latency, and highest-resolution data.",
    image: "/images/productApi.png",
    link: "",
    isComingSoon: true,
  },
];


export const statsDataNetwork = [
  { label: "Holders", value: 6000, unit: "", prefix: "+" },
  { label: "Liquidity Lock", value: 2, unit: "years", prefix: "" },
  { label: "Liquidity Provision", value: 1.3, unit: "M", prefix: "+" },
];
