// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const useIPDetector = () => {
//   const [ipUs, setIpUs] = useState(false);

//   const handleOnClick = () => {
//     setIpUs(false);
//   };

//   useEffect(() => {
//     const fetchIPData = async () => {
//       try {
//         const response = await axios.get(
//           "https://api.bigdatacloud.net/data/country-by-ip?key=bdc_21eef78005a64108b630aeed39994a68"
//         );
//         setIpUs(
//           ["US","CN", "AE"].includes(response.data.country.isoAlpha2)
//         );
//       } catch (error) {
//         console.error("Error fetching IP data:", error);
//       }
//     };

//     fetchIPData();
//   }, []);

//   return { ipUs, onClick: handleOnClick };
// };

// export default useIPDetector;

// import { useEffect, useState } from "react";
// import axios from "axios";

// const useIPDetector = () => {
//   // const [ipData, setIpUs] = useState(null);
//   const [ipUs, setIpUs] = useState(false);
//     const handleOnClick = () => {
//       setIpUs(false);
//     };
//   useEffect(() => {
//     const fetchIPData = async () => {
//       try {
//         // Fetch the user's IP address
//         const ipResponse = await axios.get(
//           "https://api.whatismyip.com/ip.php?key=8456e6178bce8a28d3797f8bacd0d87e"
//         );
//         const ipAddress = ipResponse.data;

//         // Perform IP address lookup
//         const lookupResponse = await axios.get(
//           `https://api.whatismyip.com/ip-address-lookup.php?key=8456e6178bce8a28d3797f8bacd0d87e&input=${ipAddress}`
//         );
//         const geolocation = lookupResponse.data;

//         // Set IP data
//         setIpUs(geolocation);
//       } catch (error) {
//         console.error("Error fetching IP data:", error);
//       }
//     };

//     fetchIPData();
//   }, []);

//   return { ipUs, onClick: handleOnClick };
// };

// export default useIPDetector;

//chatgpt//

// Modify useIPDetector.js
import { useEffect, useState } from "react";
import axios from "axios";
import cors from "cors";

const useIPDetector = () => {
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const fetchIPData = async () => {
      try {
        // const ipResponse = await axios.get(
        //   "https://api.whatismyip.com/ip.php?key=b08d396917b15fe4d666712bfe8a1b56	"
        // );
        // const ipAddress = ipResponse.data;

        const lookupResponse = await axios.get(
          // `https://ipinfo.io?token=9af27d71ba0f6d`
          // "https://api.ip2location.io/?key=6166A646A7EE1D8B7D9FDBB4EDE98748"
          "https://apiip.net/api/check?accessKey=f43ddf82-7c68-4bbd-8002-b44a6e3d4884"
        );
        const geolocation = lookupResponse.data;
        // const parsedGeolocation = JSON.parse(geolocation);
        // console.log(geolocation.countryCode);

        const country = geolocation.countryCode;

        // console.log("Country:", country);

    


        setCountryCode(country);
      } catch (error) {
        console.error("Error fetching IP data:", error);
      }
    };

    fetchIPData();
  }, []);

  return countryCode;
};

export default useIPDetector;
